/*
CSS pour Django-CMS
Complement à FOUNDATION 
*/

@require "mixins.styl"

html.django-site
    margin:0
    padding:0
    visibility:hidden

body
    font-family: 'Roboto', sans-serif
    color: $text_color
    margin: -1rem 0 -1rem 0

body.site-content
    background-color: $primary_color
    background: url('/static/css/background.jpg') no-repeat center center fixed 
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    background-size: cover

// For cke editor CMS plugin list
// html.cke_panel_container
body.cke_ltr
    background: none !important
  
@media (max-width: 640px)
    body
        margin-left: 10px
        margin-right: 10px

body.cke_editable
    background: none
    background-color: #fff


header
    background-color: #fff

footer
    color: #fff
    padding-left: 1rem
    padding-right: 1rem
    margin-bottom: -1rem
    background-color: $secondary_color
    a
    a:focus
    a:visited
        color: #fff



// div.header-content
div.main-content
    background-color: #fff
    //min-height: 73.2vh

    #mainContent
        div.cell
            padding-left: 1rem;
            padding-right: 1rem;
        div.cell:first-child
            padding-left: 3rem;
        div.cell:last-child
            padding-right: 3rem;

// MENU
div.navigation
    margin-top 0.5rem
    margin-bottom 0.5rem
    border-bottom 1px solid $line_color

    ul.menu
       li.selected
           font-weight: bold
           ul.submenu
               font-weight: normal
       a:hover
       a:focus
           color: $secondary_color
	   

// BREADCRUMB
ul.breadcrumbs
    padding-left: 1rem
    border-bottom 1px solid $line_color
    padding-bottom 0.5rem
    margin-bottom 0.5rem
    a
        color: $secondary_color
    li
        span.active
            color: #aaa


// CODE PRESENTATION
code
kbd
pre
samp
pre
    background-color:  rgb(238, 238, 238)
    overflow: auto
    font-family: 'Ubuntu Mono', monospace;
    font-size: 1em;
    margin: 10px 10px 30px 20px;
    padding: 10px 10px 10px 10px;
    background: #f2f6ff;
    border-style: dashed;
    border-color: #aaaaff;
    border-width: 1px;



// HEADINGS
h1
h2
h3
h4
h5
h6
    font-family: 'Lato', sans-serif;

h1
    color: $primary_color
    text-transform: uppercase
    font-weight: bold
h2
h3
h4
h5
h6
    color: $secondary_color


// IMAGES
img.right
    float: right
    padding-left: 0.5rem

img.left
    float: left
    padding-right: 0.5rem

img.center
    display: block
    align: center
    margin-left: auto
    margin-right: auto

// IMAGES' LEGENDS
img.right + span.filer_image_info
    display: none
    float: right

img.left + span.filer_image_info
    display: none
    float: left

img.center + span.filer_image_info
    display: block
    text-align: center



div.hr-before
    margin-top: 1rem
    border-top: 1px solid $line_color

div.hr-after
    margin-bottom: 1rem
    border-bottom: 1px solid $line_color

div.vertical-spacer
    height: 1rem


// TABS
li.tabs-title
    a
        font-size: 1.2em



// SEARCH PAGE
div.plugin-search
    form.frm-search
        ol
            li
                list-style-type: none

    div.search-results
        ul
            list-style: none
            padding: 0
            li
                padding-left: 1.3em
                
                h3
                    display: inline
        
            li:before
                content: "\f18e"
                font-family: FontAwesome
                font: normal normal normal 34px/1 FontAwesome
                display: inline-block
                margin-left: -1.3em
                width: 1.3em
                color: $secondary_color




/* Should prevent flash display */
.no-js .top-bar {
  display: none;
}

@media screen and (min-width: 40em) {
  .no-js .top-bar {
    display: block;
  }

  .no-js .title-bar {
    display: none;
  }
}